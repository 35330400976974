import {Character, ImageReference, LanguageText} from "./Character";

export enum NodeType {
    begin = "begin",
    location = "location",
    narrator = "narrator",
    sound = 'sound',
    npc = 'npc',
    player = 'player',
    playerCharacter = 'playerCharacter',
    objectChoice = 'objectChoice',
    puzzle = 'puzzle',
    reward = 'reward',
    takeFromInventory = 'takeFromInventory',
    activateQuest = 'activateQuest',
    triggerEvent = 'triggerEvent',
    conditional = 'conditional',
    goBackTo = 'goBackTo',
    end = 'end'
}

export enum PuzzleTypes {
    sortTheWords,
    fillTheBlank,
    multipleChoice
}

export interface LanguageTextData {
    beginnerLine?: LanguageText;
    intermediateLine?: LanguageText;
    advancedLine?: LanguageText;
}

export interface PuzzleData {
    I?: any;
    II?: any;
    III?: any;
}

export interface PlayerData extends LanguageTextData {
}

export interface NpcData extends LanguageTextData{
    character: Character;
    imgRef: ImageReference;
}
