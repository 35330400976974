import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getBgAudioSrc, getSfxAudioSrc, getVoiceAudioSrc} from "../slices/userGamePlaySlice";

export interface IAudioService {
    isPlayingAudio: () => boolean;
    enableAutoPlay:() => void;
    disableAutoPlay:() => void;
}

const AudioServiceContext = React.createContext<IAudioService | undefined>(
    undefined
);

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const AudioService = ({ children }) => {
    const bgAudioTrack = useAppSelector(getBgAudioSrc);
    const voiceTrack = useAppSelector(getVoiceAudioSrc);
    const SfxTrack = useAppSelector(getSfxAudioSrc);
    const dispatch = useAppDispatch();
    const bgmPlayer = useRef<HTMLAudioElement>(null);
    const voicePlayer = useRef<HTMLAudioElement>(null);
    const sfxPlayer = useRef<HTMLAudioElement>(null);
    const [bgAudioSrc, setBgAudioSrc] = useState<string>("https://lq-gc-audio.s3.amazonaws.com/Sweet-Life.mp3")
    const [enabledAudio, setEnabledAudio] = useState<boolean>(false);

    useEffect(()=>{
        if (!bgmPlayer.current) {
            return;
        }
        if (enabledAudio) {
            bgmPlayer.current.volume = 1;
        } else {
            bgmPlayer.current.volume = 0;
        }
    }, [enabledAudio])

    useEffect(()=>{
        if (voicePlayer.current && voiceTrack) {
            const newSrc = voiceTrack;
            if (newSrc !== "") {
                voicePlayer.current.pause();
                voicePlayer.current.src = newSrc;
                voicePlayer.current.load();
            //voicePlayer.current.play();
            }
            //setBgAudioSrc(newSrc);
        }
    },[voiceTrack])
    useEffect(()=>{
        if (bgmPlayer.current && bgAudioTrack !== bgAudioSrc) {
            const newSrc = bgAudioTrack && bgAudioTrack!=="" ? bgAudioTrack : "https://lq-gc-audio.s3.amazonaws.com/Sweet-Life.mp3";
            bgmPlayer.current.pause();
            bgmPlayer.current.src = newSrc;

            if(enabledAudio) {
                console.log(enabledAudio)
                bgmPlayer.current.load();
                //audioPlayer.current.play()
            }
            //setBgAudioSrc(newSrc);
        }
    },[bgAudioTrack])

    useEffect(()=>{
        console.log("change in audio player");
    },[bgmPlayer])

    const isPlayingAudio = () => {
        return false
    }

    const enableAutoPlay = () => {
        if (bgmPlayer.current) {
            bgmPlayer.current!.play();

        }
        setEnabledAudio(true);
    }

    const disableAutoPlay = () => {
        if (bgmPlayer.current) {
            bgmPlayer.current!.pause();
        }
        setEnabledAudio(false);
    }

    // @ts-ignore
    const isIOS = iOS();
    return (
        <AudioServiceContext.Provider
            value={{
                isPlayingAudio,
                enableAutoPlay,
                disableAutoPlay
            }}
        >

            <audio ref={bgmPlayer} loop autoPlay>
                <source src={bgAudioSrc} type="audio/mpeg" />
            </audio>

            {isIOS ? <audio ref={voicePlayer}>
                    <source src={""} type="audio/mpeg"/>
                </audio>
                : <audio ref={voicePlayer} autoPlay>
                    <source src={""} type="audio/mpeg"/>
                </audio>}
            {children}
        </AudioServiceContext.Provider>
    );
};

export const useAudioService = () => {
    const context = useContext(AudioServiceContext);
    if (context == null ) {
        throw new Error("useQuestService() called outside of a Provider?");
    }
    return context;
};
