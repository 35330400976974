import styled from "@emotion/styled";
import {
  Character,
  ImageReference, LanguageLevel,
  LanguageText,
  MediaHelper,
} from "@pal/common";
import MessageDisplay from "./MessageDisplay";
import { keyframes } from "@emotion/react";
import { fadeIn } from "react-animations";
const fadeInKeys = keyframes`${fadeIn}`;

export const Wrapper = styled.div`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-flow: column;
  margin: 100px auto 45px;
  flex: 1 1 auto;
  justify-content: center;
  @media (min-width: 900px) {
    max-height: calc(55% - 100px);
    height: calc(55% - 100px);
    justify-content: flex-end;
  }
`;

export const CharacterDisplay = styled.div<{
  position?: string;
}>`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  align-items: ${(props) => (props.position === "left") ? "flex-start" : "flex-end"};
  div {
    position: relative;
    padding-right: 10px;
    span {
      z-index: 2;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      font-size: 1.1em;
    }
    img {
      margin-right: 3%;
      height: 170px;
      object-fit: contain;
      @media (min-width: 900px) {
        height: 200px;
      }
    }
  }
`;

const ImgRef = (props: { src: ImageReference }) => {
  const Wrapper = styled.div`
    width: 50%;
    display: flex;
    flex-flow: column;
    flex: 0 0 auto;
    height: 150px;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-start;
    animation: 1s ${fadeInKeys};
    img {
      max-height: 76%;
      object-fit: contain;
      margin: 0 auto;
      background: #fff;
      border: 1px solid orange;
      border-radius: 20px;
      @media (min-width: 900px) {
        max-height: 95%;
      }
    }

    span {
      text-align: center;
      margin: -4px auto;
      padding: 0 40px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgb(190, 89, 12) 32%,
        rgb(190, 89, 12) 64%,
        rgba(0, 0, 0, 0) 100%
      );
      color: white;
      font-weight: bold;
      font-size: 1.2em;
    }
  `;
  const assetRef = props.src?.asset
    ? MediaHelper.getUrlFromAsset(props.src.asset!)
    : null;
  return assetRef ? (
    <Wrapper>
      <img src={assetRef} />
      <span>{props.src.name}</span>
    </Wrapper>
  ) : (
    <></>
  );
};

const NpcDialogueBox = (props: {
  showNext: boolean;
  onNext: () => void;
  character: Character;
  languageLevel?: LanguageLevel,
  message?: LanguageText;
  imgRef?: ImageReference;
}) => {
  const { showNext, onNext, character, message, imgRef, languageLevel } = props;

  const portraitUrl = MediaHelper.getUrlFromAsset(character.portrait);

  return (
    <Wrapper key={character.cid + "_w"}>
      <div style={{ display: "flex" }}>
        {imgRef && languageLevel === "beginner" && <ImgRef src={imgRef} />}
        <CharacterDisplay key={character.cid + "_cd"}>
          <div>
            <img src={portraitUrl} alt={character.name} />
            <span>{character.name}</span>
          </div>
        </CharacterDisplay>
      </div>
      <MessageDisplay
        key={character.cid + "_md"}
        showNext={showNext}
        onNext={onNext}
        message={message}
      />
    </Wrapper>
  );
};

export default NpcDialogueBox;
