import {BaseModel} from "./BaseModel";
import {Asset} from "./Asset";
import {City} from "./City";
import {Character} from "@pal/common";
import {LanguageText} from "./Character";

export interface DefaultOptions {
    1: LanguageText;
    2: LanguageText;
    3: LanguageText;
}

export interface GameLocation extends BaseModel {
    lid: string;
    isPlayerHome?: boolean;
    isTaxiLocation?: boolean;
    requirements?: {
        finishedQuests?: string[];
        duringQuests?: string[];
    }
    city: City;
    name: string;
    description: string;
    defaultBackground: Asset;
    defaultBgMusic:Asset;
    lat:string;
    lng:string;
    character: Character;
    defaultDialogue: DefaultOptions;
    lastPlayerOption:DefaultOptions;
}

export const GameLocationfetchAggregationPipeline = [
    {$addFields: {"convertedCharacter_id": {$toObjectId: "$character._id"}}},
    {$addFields: {"convertedCity_id": {$toObjectId: "$city._id"}}},
    {
        $lookup: {
            from: "characters",
            localField: "convertedCharacter_id",
            foreignField: "_id",
            as: "character"
        },
    },
    {
        $lookup: {
            from: "cities",
            localField: "convertedCity_id",
            foreignField: "_id",
            as: "city"
        },
    },
    {
        $unwind: {
            path: "$character",
            preserveNullAndEmptyArrays: true
        }
    },
    {
        $unwind: {
            path: "$city",
            preserveNullAndEmptyArrays: true
        }
    },
    {$addFields: {"character._id": {$toString: "$character._id"}}},
    {$addFields: {"city._id": {$toString: "$city._id"}}},
    {$unset: "convertedCharacter_id"},
    {$unset: "convertedCity_id"},
]
