
import Map, {Marker} from "react-map-gl";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  BaseDAL,
  GameLocation,
  GameLocationfetchAggregationPipeline,
  mapRange,
  MediaHelper,
  useMongoDB,
  UserGameplayData,
} from "@pal/common";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getSavedPositions, setMapPosition} from "../slices/userGamePlaySlice";
import {getPlayingCity, setPlayingLocation,} from "../slices/userGamePlaySlice";
import {useHistory} from "react-router-dom";
import NavigationBar from "../components/layout/NavigationBar";
import {keyframes} from "@emotion/react";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import {useTheme} from "@mui/styles";
import {gameTheme} from "../style/gametheme";
import {
  getCompletedQuestsIds,
  getLastOpenLocations,
  getNextLocationId,
  getTriggeredEvents, setLastOpenLocationsInCity
} from "../slices/userGamePlaySlice";
import {bounceIn, fadeIn, merge, slideInDown} from "react-animations";
import {useQuestService} from "../services/QuestService";
import {styled} from "@mui/material";

const screenTransitionIn = keyframes`${fadeIn}`;


const rotateAtTheEnd = {
  '0%': {
    transform: "rotate(0deg)",
  },
  '80%': {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(10deg)",
  }
};

const mergeAnimation = merge(slideInDown, rotateAtTheEnd);
const nextLocationAnimation = keyframes`${mergeAnimation}`;
const gameLocationPopAnimation = keyframes`${bounceIn}`;


const NextLocationIcon = styled(NotListedLocationIcon)`
  
  position:absolute; 
  top:-80px;
  right:-38px;
  font-size:10em;
  animation: 1.5s ${nextLocationAnimation};
  transform: rotate(10deg);
  transform-origin: 50% 100%;
`

const MapWrapper = styled('div')`
  height: 100%;
  width: 100%;
  background-color: lightblue;
  animation: 2.4s ${screenTransitionIn};
`;

const GameLocationMarker = styled('div')<{ bgUrl: string, animate?:boolean }>`
  cursor: pointer;
  background-image: url(${(props) => props.bgUrl});
  background-size: cover;
  background-position: center center;
  width: 260px;
  height: 120px;
  background-color: #ffaa55;
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
  border-radius: 10px;
  box-shadow: 0px 7px 9px 5px rgba(49, 24, 86, 0.75);
  animation: 1.2s ${gameLocationPopAnimation};
  animation-iteration-count: ${(props) => props.animate ? '1' : '0'};
  
  div {
    background-color: #fd9a46;
    color: #3d2975;
    padding: 0px 5px;
    clip-path: polygon(0 60%, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    align-items: flex-end;
    height: 25%;

    span {
      width: 100%;
      text-align: right;
      color: #ffffff;
      font-weight: bold;
      font-family: "Saira Condensed";
      font-size: 1.1em;
      height: fit-content;
      font-size: 1.5em;
      line-height: 1.2em;
    }
  }

  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 7px 20px 5px rgba(49, 24, 86, 0.75);
    //transform:scale(1.1);
    transform: scale(1.1);

    div {
    }

    span {
      color: #280470;
      color: white;
    }
  }
`;

export const MapColorFilter = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  mix-blend-mode: soft-light;
  background-color: #27ad4a94;
  background: rgb(255, 212, 33);
  background: radial-gradient(circle, #ff910044 0%, rgba(8, 0, 109, 0.4) 100%);

  pointer-events: none;
  z-index: 10;
`;

export const MapOverlayUI = styled('div')`
  display: flex;
  flex-flow: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  pointer-events: none;
  padding: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 229, 178, 0) 15%,
    rgba(255, 206, 110, 0) 85%,
    rgb(255, 255, 255) 100%
  );
`;


const CityMap = () => {
  const { db } = useMongoDB();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const playingCity = useAppSelector(getPlayingCity);
  const triggeredEvents = useAppSelector(getTriggeredEvents);
  const completedQuestsIds = useAppSelector(getCompletedQuestsIds);
  const questService = useQuestService();
  const nextLocationId = useAppSelector(getNextLocationId);
  const savedPositions = useAppSelector(getSavedPositions);
  const lastOpenLocations = useAppSelector(getLastOpenLocations);
  const lastOpenLocationsInCity = lastOpenLocations.find(cityLocation => cityLocation.cityCode === playingCity?.cityCode)?.gameLocationIds || [];

  const savedPosition = savedPositions?.find(
    (item) => item.cityCode === playingCity?.cityCode
  )?.position;

  const savedZoom = savedPositions?.find(
    (item) => item.cityCode === playingCity?.cityCode
  )?.zoom;
  const openQuests = questService.getOpenCityQuests();

  const defaultCenter: [number, number] = savedPosition
    ? savedPosition
    : playingCity
    ? [parseFloat(playingCity.lat), parseFloat(playingCity.lng)]
    : [0, 0];

  const [center, setCenter] = useState<[number, number]>(defaultCenter);
  const centerRef = useRef(center);

  React.useEffect(() => {
    centerRef.current = center;  // Update the ref whenever `value` changes
  }, [center]);

  const [zoom, setZoom] = useState(savedZoom ? savedZoom : 15);
  const zoomRef = useRef(zoom);

  React.useEffect(() => {
    zoomRef.current = zoom;  // Update the ref whenever `value` changes
  }, [zoom]);

  const [allCityGameLocations, setAllCityGameLocations] = useState<GameLocation[]>([]);
  const [openGameLocations, setOpenGameLocations] = useState<GameLocation[]>([]);
  const theme = useTheme();

  useEffect(() => {
    async function wrapFetch() {
      if (playingCity) {
        let aggPipeline: any[] = [...GameLocationfetchAggregationPipeline];
        aggPipeline.unshift({
          $match: {
            "city.cityCode": playingCity!.cityCode,
          },
        });
        let locationsDAL = new BaseDAL<GameLocation>("locations");
        const AllCityGameLocations = await locationsDAL.fetchAggregate(aggPipeline);
        setAllCityGameLocations(AllCityGameLocations);
      }
    }
    wrapFetch();
  }, [db, playingCity]);

  function gameLocationArrayIsEqual(a:GameLocation[], b:GameLocation[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i].lid !== b[i].lid) return false;
    }
    return true;
  }

  useEffect(()=>{
    const openGameLocationsTemp:GameLocation[] = [];

    const conditionsAreMet = (gameLocation:GameLocation) => {
      if(gameLocation.requirements?.finishedQuests?.length) {
        for (let fq = 0; fq < gameLocation.requirements.finishedQuests.length; fq++) {
          if (!completedQuestsIds.includes(gameLocation.requirements.finishedQuests[fq])) {
            return false;
          }
        }
      }
      if(gameLocation.requirements?.duringQuests?.length) {
        if (!openQuests.filter((quest)=>gameLocation.requirements?.duringQuests?.includes(quest.qid)).length) {
          return false;
        }
      }
      return true;
    }

    allCityGameLocations.forEach((gameLocation) => {
      if (conditionsAreMet(gameLocation)) {
        openGameLocationsTemp.push(gameLocation);
      }

    })
    if (!gameLocationArrayIsEqual(openGameLocationsTemp, openGameLocations)) {
      setOpenGameLocations(openGameLocationsTemp);
    }
  }, [allCityGameLocations, completedQuestsIds, openQuests, triggeredEvents])

  const newGameLocations = useMemo(()=>{
    let newGameLocations = openGameLocations.filter(location => !lastOpenLocationsInCity?.includes(location.lid)).map(location => location.lid) || [];
    return newGameLocations
  },[openGameLocations]);

  useEffect(
      () => {
        let timer1 = setTimeout(() => {
          if (newGameLocations.length) {
            const newArray = lastOpenLocationsInCity.concat(newGameLocations)
            dispatch(setLastOpenLocationsInCity({cityCode: playingCity!.cityCode, gameLocationIds:newArray!}))
          }
        }, 100);

        return () => {
          clearTimeout(timer1);
        };
      },
      [newGameLocations]
  );

  const pushToGameLocationStage = (gameLocation: GameLocation) => {
    dispatch(
      setMapPosition({
        cityCode: playingCity!.cityCode,
        position: centerRef.current,
        zoom: zoomRef.current,
      })
    );
    dispatch(setPlayingLocation(gameLocation));
    history.push("/gamelocation/" + gameLocation?.lid);
  };

  const minZoom = 12;
  const maxZoom = 19;
  const markerScale = mapRange(zoom, minZoom, maxZoom, 0.1, 1.6);
  const defaultZoom = savedZoom ? savedZoom : 14;

  const locationMarkers = useMemo(() => {
    return openGameLocations.map((location: GameLocation, index: number) => {
      const coverUrl = MediaHelper.getUrlFromAsset(location.defaultBackground);
      const popup = lastOpenLocationsInCity.length ? newGameLocations.includes(location.lid) : false;
      return (
        <Marker
          key={location.name}
          longitude={parseFloat(location.lng)}
          latitude={parseFloat(location.lat)}
          anchor="center"
        >
          <div style={{ transform: `scale(${markerScale})` }}>
            <GameLocationMarker
              bgUrl={coverUrl}
              key={index}
              onClick={() => pushToGameLocationStage(location)}
              animate={popup}
            >
              <div>
                <span>{location.name}</span>
              </div>
              {nextLocationId === location.lid && <NextLocationIcon sx={{color:gameTheme.palette.primary.main}} />}
            </GameLocationMarker>
          </div>
        </Marker>
      );
    });
  }, [openGameLocations, newGameLocations, zoom, nextLocationId]);

  return (
    <MapWrapper>
      <Map
        mapLib={maplibregl}
        mapStyle="https://api.maptiler.com/maps/basic/style.json?key=RktddaK9xhMsbHiDECmR"
        maxZoom={maxZoom}
        minZoom={minZoom}
        initialViewState={{
          longitude: defaultCenter[1],
          latitude: defaultCenter[0],
          zoom: defaultZoom,
        }}
        onMove={(evt) => {
          setCenter([evt.viewState?.latitude, evt.viewState?.longitude]);
          setZoom(evt.viewState.zoom);
        }}
      >
        <MapColorFilter />
        {locationMarkers}
      </Map>
      <MapOverlayUI>
        <div style={{ flex: "1 1 auto" }}></div>
        <NavigationBar showNavToMainMenu={true} />
      </MapOverlayUI>
    </MapWrapper>
  );
};

export default CityMap;
