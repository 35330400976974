import {NodeType, Quest, QuestNode, QuestTrack} from "@pal/common";
import {StageNode, StageScript} from "./StageScript";

export const playerInputTypes:string[] = [
  NodeType.player.toString(),
  NodeType.puzzle.toString(),
  NodeType.objectChoice.toString(),
];
const blockedStartNodes:string[] = [NodeType.begin.toString(), NodeType.location.toString()].concat(playerInputTypes)
export const MAX_INPUT_NODES = 5;

const getQuestStageScript = (
  currentLocation: string,
  quest: Quest,
  questTracks: QuestTrack[]
) => {
  if (quest.elements.length > 1) {
    //begin or zero if there's no node on QuestTrack
    const questTrack = questTracks.find((q) => q.qid === quest.qid);
    const usableStartNode = (questTrack?.conditionToReprocess && questTrack?.conditionToReprocess !== "") ? questTrack?.conditionToReprocess : questTrack?.currentNodeId;
    let scriptOrigin =
        usableStartNode
        ? quest.nodes.find((node) => node.id === usableStartNode)!
        : quest.nodes.find((node) => node.type === "begin") || quest.nodes[0];
    let startNode = scriptOrigin;

    let questLocation = questTrack?.currentLocation;
    const initialPreInputSequence = (!blockedStartNodes.includes(startNode.type)) ?
      [{ qid: quest.qid, questNode: startNode }] : [];

    let questStageScript: StageScript = {
      mainQuest: quest.qid,
      preInputSequence: initialPreInputSequence,
      playerInput: [],
    };

    if (questLocation && questLocation !== currentLocation && !playerInputTypes.includes(startNode.type)) {
      return null;
    }

    const processNextLevelFromStartNode = (startNode:QuestNode) => {
      const outEdges = quest.edges.filter(
        (edge) => edge.source === startNode.id
      );
      const nextTargets = outEdges.map((e) => e.target);
      const next = quest.nodes.filter((node) => nextTargets.includes(node.id));

      const isNextInCurrentLocation = () => {
        if (next.length > 0 && next[0].type === NodeType.location) {
          //assuming quests only branch in player inputs
          questLocation = next.map((node) => node.data.location.lid)[0];
          return questLocation === currentLocation;
        }
        return questLocation ? questLocation === currentLocation : false;
      };

      const isQuestNodeOnlyLocationMarker = (questNode: QuestNode) => {
        return ((questNode.data.location &&
                questNode.data.location.lid === currentLocation && !(questNode.data.alternativeBackground || questNode.data.alternativeBgMusic))
        );
      };

      if (next.length > 0 && isNextInCurrentLocation()) {
        const stageNodes = next.map((node): StageNode => {
          return { qid: quest.qid, questNode: node };
        });
        if (playerInputTypes.includes(stageNodes[0].questNode.type)) {
          questStageScript.playerInput =
            questStageScript.playerInput.concat(stageNodes);
        } else {
          const shouldProceed = startNode.type !== NodeType.conditional
          if(shouldProceed) {
            if (!isQuestNodeOnlyLocationMarker(next[0])){
              //at first lets assume its not branching on scenarios.
              questStageScript.preInputSequence =
                  questStageScript.preInputSequence.concat(stageNodes);
            }

            const nextIteration = next.filter((node)=>(!playerInputTypes.includes(node.type)));
            for (let i=0; i < nextIteration.length; i++) {
              const nextStartNode = nextIteration[i];
              processNextLevelFromStartNode(nextStartNode);
            }
          }
        }
      }
    }

    processNextLevelFromStartNode(startNode);
    return questStageScript;
  }

  return { preInputSequence: [], playerInput: [] };
};

const assembleScriptWithMultipleQuestScripts = (
  questStageScripts: StageScript[],
  questTracks: QuestTrack[],
  currentActiveQuestId?: string,
): StageScript => {
  function pickScriptWithInitialPreInputSequenceAndItsPlayerInput(): StageScript {
    let initialScript: StageScript = { preInputSequence: [], playerInput: [] };
    let biggestStepsDone = -1;
    questStageScripts.forEach((stageScript) => {
      //console.log("iterating questStageScripts", stageScript)
      const currentTrack = questTracks.find(
        (questTrack) => questTrack.qid === stageScript.mainQuest
      );
      const stepsDoneInCurrentTrack = currentTrack?.stepsDone || 0;
      const initialPreInputSequenceRealLength = initialScript.preInputSequence.filter((node)=> node.questNode.type === NodeType.npc || node.questNode.type === NodeType.narrator)?.length
      const stageScriptPreInputRealLength = stageScript.preInputSequence.filter((node)=> node.questNode.type === NodeType.npc || node.questNode.type === NodeType.narrator)?.length

      function newScriptHasPriorityOverPrevious() {
        if (
          !initialScript.preInputSequence.length &&
          !initialScript.playerInput.length
        )
          return stageScript.preInputSequence.length ||
            stageScript.playerInput.length
            ? true
            : false;
        if (
          !initialPreInputSequenceRealLength && stageScriptPreInputRealLength
        )
          return true;
        if (
            initialPreInputSequenceRealLength && stageScriptPreInputRealLength
        )
          return biggestStepsDone < stepsDoneInCurrentTrack;
        // if (stageScript.playerInput.length && !initialScript.playerInput.length) return true; favours playerInput over preInputSequence
        return false;
      }
      if (newScriptHasPriorityOverPrevious()) {
        initialScript = stageScript;
        biggestStepsDone = stepsDoneInCurrentTrack;
      }
    });
    return initialScript;
  }

  function aggregateOtherQuestInputsIfAnyAvailable(initialScript): StageScript {
    let script = initialScript;

    questStageScripts.sort((firstEl, secondEl) => {
      const firstQuestTrackStepsDone =
        questTracks.find((questTrack) => questTrack.qid === firstEl.mainQuest)
          ?.stepsDone || 0;
      const secondQuestTrackStepsDone =
        questTracks.find((questTrack) => questTrack.qid === secondEl.mainQuest)
          ?.stepsDone || 0;
      return firstQuestTrackStepsDone - secondQuestTrackStepsDone;
    });

    questStageScripts.forEach((auxScript) => {
      if (
        auxScript.mainQuest !== initialScript.mainQuest &&
        !auxScript.preInputSequence.length &&
        auxScript.playerInput.length + script.playerInput.length <=
          MAX_INPUT_NODES
      )
        script.playerInput = script.playerInput.concat(auxScript.playerInput);
    });
    return script;
  }

  const activeQuestStage = questStageScripts.find((qs) => qs.mainQuest === currentActiveQuestId && (qs.playerInput.length || qs.preInputSequence.length))
  const initialScript = (activeQuestStage) ? activeQuestStage :
    pickScriptWithInitialPreInputSequenceAndItsPlayerInput();
  //console.log("picked initialScript", initialScript)
  const script = !initialScript.preInputSequence.length
    ? aggregateOtherQuestInputsIfAnyAvailable(initialScript)
    : initialScript;
  return script;
};

const StageScriptWriter = (
  currentLocation: string,
  openQuests: Quest[],
  questTracks: QuestTrack[],
  currentActiveQuestId?: string,
): StageScript => {

  // @ts-ignore
  const questStageScripts: StageScript[] = openQuests.map((quest) => getQuestStageScript(currentLocation, quest, questTracks)).filter((q) => q!==null);

  if (questStageScripts.length === 1) {
    return questStageScripts[0];
  } else {
    return assembleScriptWithMultipleQuestScripts(
      questStageScripts,
      questTracks, currentActiveQuestId,
    );
  }
};

export default StageScriptWriter;
