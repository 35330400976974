export enum AssetType {
    // audio
    bgm,
    sfx,
    voice,

    // graphics
    bga,
    character,
    object,
    gameicon,
    cover,

    video,
}
